import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'uikit'

const MainLayout = ({ children, noPadding, flexDirection }) => (
  <View
    id="main-content"
    flexDirection={flexDirection}
    flexGrow={1}
    p={noPadding ? 0 : ['20px 0', '25px 0', '30px 0', '40px 0']}
  >
    {children}
  </View>
)

MainLayout.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
}

export default MainLayout

/* eslint-disable react/display-name */
export const asLayout =
  ({ noPadding = false, flexDirection }) =>
  page =>
    (
      <MainLayout flexDirection={flexDirection} noPadding={noPadding}>
        {page}
      </MainLayout>
    )
