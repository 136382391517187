import PropTypes from 'prop-types'
import { default as React } from 'react'
import Swipeout from 'rc-swipeout'
import 'rc-swipeout/assets/index.css'

import { alertComponent } from './alerts'
import { Alert } from '../../reducers/alerts'

import { themeGet } from 'styled-system'

const NotificationAlert = ({ alert, onDismiss }) => {
  const Component = alertComponent(alert.alertType)
  return Component ? (
    <Swipeout
      right={[
        {
          text: ' ',
          style: { backgroundColor: themeGet('colors.crimson'), color: themeGet('colors.white') },
          onPress: onDismiss,
        },
      ]}
    >
      <Component alert={alert} onDismiss={onDismiss} />
    </Swipeout>
  ) : null
}

NotificationAlert.propTypes = {
  alert: PropTypes.instanceOf(Alert),
  onDismiss: PropTypes.func.isRequired,
}

export default NotificationAlert
