import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Container, ButtonsHolder } from 'components/MarkupHelpers'
import { CtaButton } from 'uikit'

const StyledFooter = styled.footer`
  font-size: 24px;
  line-height: 1.25;
  color: ${props => props.theme.colors.doveGray};
  padding: 80px 0;
  background: ${props => props.theme.colors.wildSand};
  text-align: center;
`

const StyledTitle = styled.h2`
  font-size: 36px;
  line-height: 1.25;
  color: ${props => props.theme.colors.tuatara};
  margin: 0 auto 40px auto;
  max-width: 830px;
  font-weight: 300;
`

const StyledTextHolder = styled.div`
  margin: 0 auto;
  max-width: 830px;
  padding-bottom: 30px;
`

const FooterQA = ({ buttonTitle, message, onJoin, title }) => {
  const onClick = e => {
    e.preventDefault()

    if (onJoin) {
      onJoin()
    }
  }

  return (
    <StyledFooter>
      <Container>
        <StyledTitle>{title}</StyledTitle>
        <StyledTextHolder>{message}</StyledTextHolder>
        <ButtonsHolder>
          <CtaButton size="huge" onClick={onClick}>
            {buttonTitle}
          </CtaButton>
        </ButtonsHolder>
      </Container>
    </StyledFooter>
  )
}

FooterQA.propTypes = {
  buttonTitle: PropTypes.string,
  message: PropTypes.string,
  onJoin: PropTypes.func,
  title: PropTypes.string,
}

export default FooterQA
