import PropTypes from 'prop-types'
import { default as React } from 'react'

import ImmutablePropTypes from 'react-immutable-proptypes'
import { Icon } from 'uikit'

import NotificationAlert from './NotificationAlert'
import { useIsImpersonated } from 'reason/common/CurrentUser/CurrentUser.bs'

const NotificationAlertSection = ({ group, alerts, dismissAlert, dismissAlertGroup }) => {
  const isImpersonated = useIsImpersonated()

  return (
    <div className="notifications-section">
      <h4 className="notification-title">
        {group}
        <a
          href="#"
          className="close-trigger"
          onClick={e => {
            e.preventDefault()
            if (!isImpersonated) {
              dismissAlertGroup(group)
            }
          }}
        >
          <Icon icon="cancel" />
        </a>
      </h4>
      {alerts
        .map((alert, id) => (
          <NotificationAlert
            alert={alert}
            key={id}
            onDismiss={() => {
              if (!isImpersonated) {
                dismissAlert(id, alert.alertStatus)
              }
            }}
          />
        ))
        .valueSeq()
        .toArray()}
    </div>
  )
}

NotificationAlertSection.propTypes = {
  group: PropTypes.string.isRequired,
  alerts: ImmutablePropTypes.map,
  dismissAlert: PropTypes.func.isRequired,
  dismissAlertGroup: PropTypes.func.isRequired,
}

export default NotificationAlertSection
