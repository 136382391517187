import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { View } from 'uikit'

import { Container } from 'components/MarkupHelpers'

import NotificationDropdown from 'components/NotificationDropdown'

import ProfileDropdown from '../../reason/components/ProfileDropdown.bs'

import HeaderSearchDropdown from '../../reason/components/HeaderSearchDropdown.bs'

import LogoDesktop from '../../../assets/images/logos/logo-desktop.svg'
import LogoTablet from '../../../assets/images/logos/logo-mobile.svg'

import { Flex } from '../View'

// The Next image is not compatible with picture elements
/* eslint-disable @next/next/no-img-element, @next/next/no-html-link-for-pages */

const LogoMobile = props => {
  const { topNavIsOpened } = props
  const logoFillColor = topNavIsOpened ? '#1b3549' : '#909294'
  return (
    <svg width="34px" height="27px" viewBox="0 0 34 27" className="hidden-print">
      <g id="logo-mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Shape"
          fill="#1b3549"
          points="20.257 10.963 23.48 10.963 16.684 0 9.889 10.963 13.112 10.963 16.684 5.2"
        />
        <path
          d="M26.776,16.28 L25.078,13.541 L8.291,13.541 L6.593,16.28 L26.776,16.28 Z M30.073,21.598 L28.374,18.858 L4.994,18.858 L3.296,21.598 L30.073,21.598 Z M33.369,26.915 L31.671,24.176 L1.698,24.176 L0,26.915 L33.369,26.915 Z"
          id="Shape"
          fill={logoFillColor}
        />
      </g>
    </svg>
  )
}

const HeaderLogo = props => {
  const { mobileNavToggle, topNavIsOpened, isLoggedIn, userRole } = props

  // It's important that what's returned from here doesn't have media queries
  // so it works on SSR
  if (!isLoggedIn) {
    return (
      <View flexDirection="row">
        <a href="/">
          <picture>
            <source srcSet={LogoDesktop} media="(min-width: 1200px)" />
            <img alt="logo" src={LogoTablet} />
          </picture>
        </a>
      </View>
    )
  }

  return (
    <View flexDirection="row">
      <MediaQuery minWidth={545}>
        <a href={userRole === 'client' ? '/client' : '/'}>
          <picture>
            <source srcSet={LogoDesktop} media="(min-width: 1200px)" />
            <img alt="logo" src={LogoTablet} />
          </picture>
        </a>
      </MediaQuery>
      <MediaQuery maxWidth={544}>
        <Flex onClick={mobileNavToggle}>
          <LogoMobile topNavIsOpened={topNavIsOpened} />
        </Flex>
      </MediaQuery>
    </View>
  )
}

class Header extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userRole: PropTypes.oneOf(['agent', 'client']),
    handleSignout: PropTypes.func.isRequired,
    mobileNavToggle: PropTypes.func.isRequired,
    topNavIsOpened: PropTypes.bool.isRequired,
  }

  loggedInContent() {
    return (
      <Container>
        <View alignItems="center" justifyContent="space-between" height="67px">
          <HeaderLogo
            mobileNavToggle={this.props.mobileNavToggle}
            topNavIsOpened={this.props.topNavIsOpened}
            isLoggedIn={this.props.isLoggedIn}
            userRole={this.props.userRole}
          />
          <View alignItems="center" alignSelf="stretch">
            <View alignItems="center" alignSelf="stretch" position="relative">
              {this.props.userRole === 'agent' ? (
                <>
                  <HeaderSearchDropdown />
                  <NotificationDropdown />
                </>
              ) : (
                React.null
              )}
              <ProfileDropdown handleSignout={this.props.handleSignout} />
            </View>
          </View>
        </View>
      </Container>
    )
  }

  loggedOutContent() {
    return (
      <Container>
        <View alignItems="center" justifyContent="space-between" height="67px">
          <HeaderLogo mobileNavToggle={this.props.mobileNavToggle} />
        </View>
      </Container>
    )
  }

  render() {
    const { isLoggedIn } = this.props
    const content = isLoggedIn ? this.loggedInContent() : this.loggedOutContent()
    return (
      <View
        as="header"
        bg="white"
        borderBottom={isLoggedIn ? 'none' : '1px solid'}
        borderColor="alto"
      >
        {content}
      </View>
    )
  }
}

export default Header
