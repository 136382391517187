// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Theme__Color = require("../../../../reason/common/Theme/Theme__Color.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function evalInt(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else if (x === "large") {
    return 90;
  } else if (x === "medium") {
    return 70;
  } else if (x === "small") {
    return 50;
  } else {
    return 40;
  }
}

var Size = {
  evalInt: evalInt
};

function style(sz) {
  return Curry._1(Css.style, {
              hd: Css.flexShrink(0),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.borderRadius({
                        NAME: "percent",
                        VAL: 50.0
                      }),
                  tl: {
                    hd: Css.objectFit("cover"),
                    tl: {
                      hd: Css.width(sz),
                      tl: {
                        hd: Css.height(sz),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function Avatar__Jsx3$Avatar(props) {
  var __size = props.size;
  var name = props.name;
  var size = __size !== undefined ? __size : "small";
  var sizePx = evalInt(size);
  var style$1 = style({
        NAME: "px",
        VAL: sizePx
      });
  return JsxRuntime.jsx("img", {
              className: style$1,
              title: name,
              alt: name,
              src: props.src,
              onClick: props.onClick
            });
}

var Avatar = {
  style: style,
  make: Avatar__Jsx3$Avatar
};

function Avatar__Jsx3$Group(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "small";
  var sizePx = evalInt(size);
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(sizePx)),
                      tl: {
                        hd: Css.height(Css.px(sizePx)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexShrink(0),
                            tl: {
                              hd: Css.selector("& > *", {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.width(Css.px(sizePx - 13 | 0)),
                                      tl: {
                                        hd: Css.height(Css.px(sizePx - 13 | 0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: {
                                hd: Css.selector("& :nth-child(1)", {
                                      hd: Css.top(Css.px(13)),
                                      tl: {
                                        hd: Css.left(Css.px(13)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.selector("& :nth-child(2)", {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(3), undefined, Theme__Color.white)),
                                        tl: {
                                          hd: Css.top(Css.px(0)),
                                          tl: {
                                            hd: Css.left(Css.px(0)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            });
}

var Group = {
  make: Avatar__Jsx3$Group
};

var make = Avatar__Jsx3$Avatar;

exports.Size = Size;
exports.Avatar = Avatar;
exports.make = make;
exports.Group = Group;
/* Css Not a pure module */
