import PropTypes from 'prop-types'
import React from 'react'
import formatDistance from 'date-fns/formatDistance'
import { SecondaryButton } from 'uikit'
import { Link } from 'reason/next/Link'
import Image from 'next/image'

import avatarSrcUrl from 'utils/avatarSrcUrl'

import { Alert } from '../../../reducers/alerts'
import DismissAlert from './DismissAlert'

const SingleClientNeedMatchAlert = ({ client }) => {
  const displayName = client.firstName
    ? `${client.displayShortName()}'property`
    : client.displayShortName()

  return <Link href={`/my_clients/${client.id}`}>{displayName}</Link>
}

const MultipleClientNeedsMatchAlert = ({ clients, agentMatchLog, alert }) => (
  <Link href={`/match_log/${agentMatchLog.id}`}>
    {clients.size} of your {alert.alertType === 'RentalNeedMatch' ? 'clients' : 'sellers'}
  </Link>
)

const MatchingClients = ({ clients, agentMatchLog, alert }) => {
  if (clients.size > 1) {
    return (
      <MultipleClientNeedsMatchAlert
        clients={clients}
        agentMatchLog={agentMatchLog}
        alert={alert}
      />
    )
  }

  return <SingleClientNeedMatchAlert client={clients.get(0)} />
}

MatchingClients.propTypes = {
  clients: PropTypes.object.isRequired,
  agentMatchLog: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
}

const MatchLink = ({ agentMatchLog, onDismiss }) => (
  <Link href={`/match_log/${agentMatchLog.id}`}>
    <SecondaryButton onClick={onDismiss} size="small">
      View Match
    </SecondaryButton>
  </Link>
)

MatchLink.propTypes = {
  agentMatchLog: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

const ClientNeedMatchAlert = ({ alert, onDismiss }) => {
  const matchingClientType = alert.alertType === 'RentalNeedMatch' ? 'renter' : 'buyer'
  const verb = alert.alertCause === 'updated' ? 'updated' : 'has'
  return (
    <div className="item-inner">
      {!alert.agent ? (
        <>
          <div className="userpic-holder hidden-xs-down">
            <Image
              unoptimized
              width={50}
              height={50}
              layout="fixed"
              src={avatarSrcUrl()}
              alt="Anonymous agent"
            />
          </div>
          <div className="content-holder">
            Anonymous agent {verb} a {matchingClientType} that matches{' '}
            <MatchingClients
              alert={alert}
              clients={alert.clients}
              agentMatchLog={alert.agentMatchLog}
            />
            <div className="time">
              {formatDistance(new Date(alert.agentMatchLog.matchedAt), new Date())} ago
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="userpic-holder hidden-xs-down">
            <Link href={`/agents/${alert.agent.id}`}>
              <Image
                unoptimized
                width={50}
                height={50}
                layout="fixed"
                src={avatarSrcUrl(alert.agent.avatar ? alert.agent.avatar.toJS() : null)}
                alt=""
              />
            </Link>
          </div>
          <div className="content-holder">
            <div>
              <Link href={`/agents/${alert.agent.id}`} className="user-name">
                {alert.agent.displayName()}
              </Link>{' '}
              has a {matchingClientType} that matches{' '}
              <MatchingClients
                alert={alert}
                clients={alert.clients}
                agentMatchLog={alert.agentMatchLog}
              />
            </div>
            <div className="time">
              {formatDistance(new Date(alert.agentMatchLog.matchedAt), new Date())} ago
            </div>
          </div>
        </>
      )}
      <div className="action-holder">
        <MatchLink
          agentMatchLog={alert.agentMatchLog}
          clients={alert.clients}
          onDismiss={onDismiss}
        />
      </div>
      <div className="notification-close-holder">
        <DismissAlert onDismiss={onDismiss} />
      </div>
    </div>
  )
}

ClientNeedMatchAlert.propTypes = {
  alert: PropTypes.instanceOf(Alert),
}

export default ClientNeedMatchAlert
