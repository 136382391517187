import styled from 'styled-components'
import { space, fontSize, width, color, fontWeight, borderWidth, borderColor } from 'styled-system'
import { lineHeight, borderStyle } from './css'

const Ul = styled.ul`
  list-style: none;
  ${space} ${width} ${color} ${fontWeight} ${fontSize} ${borderStyle} ${borderWidth} ${borderColor}  ${lineHeight};
`

export default Ul
