import { isEmpty } from 'ramda'

import defaultSrcUrl from '../../assets/images/elements/avatar-default.svg'
import attachmentUrl from './attachmentUrl'

const avatarSrcUrl = (avatar, { width = 60, height = 60, placeholder = defaultSrcUrl } = {}) =>
  avatar && !isEmpty(avatar)
    ? attachmentUrl({ key: avatar.key, width, height, crop: true })
    : placeholder

export default avatarSrcUrl
