import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const QUERY = gql`
  query GetCodeHasChanged {
    codeHasChanged @client
  }
`

export const useCodeHasChanged = () => {
  const { data } = useQuery(QUERY)

  if (data) {
    return data.codeHasChanged
  }
  return undefined
}
