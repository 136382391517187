import React from 'react'
import ReactDOM from 'react-dom'

export default function enhanceWithClickOutside(Component) {
  const componentName = Component.displayName || Component.name

  class EnhancedComponent extends React.Component {
    componentDidMount() {
      document.addEventListener('click', this.handleClick, true)
      // https://github.com/kentor/react-click-outside/issues/4#issuecomment-266644870
      if ('ontouchstart' in document.documentElement) {
        document.body.style.cursor = 'pointer'
        document.body.style['-webkit-tap-highlight-color'] = 'transparent'
      }
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClick, true)
      if ('ontouchstart' in document.documentElement) {
        document.body.style.cursor = null
        document.body.style['-webkit-tap-highlight-color'] = null
      }
    }

    __domNode = null

    handleClick = e => {
      const domNode = this.__domNode
      if (
        (!domNode || !domNode.contains(e.target)) &&
        typeof this.props.handleClickOutside === 'function'
      ) {
        this.props.handleClickOutside(e)
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { handleClickOutside: _, ...props } = this.props
      return (
        <Component
          {...props}
          ref={c => {
            // eslint-disable-next-line react/no-find-dom-node
            this.__domNode = ReactDOM.findDOMNode(c)
          }}
        />
      )
    }
  }

  EnhancedComponent.displayName = `clickOutside(${componentName})`

  return EnhancedComponent
}
