import React from 'react'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'

import MediaQuery from 'react-responsive'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Icon } from 'uikit'

import notificationAlert from '../modules/NotificationAlert'

import { selectNewAlerts } from '../reducers/alerts'

import enhanceWithClickOutside from './clickOutside'

import SvgIcon from './SvgIcon'
import { iconBell, iconBellFilled } from '../styles/svgIcons'
import { mediaBreakpointUp, mediaBreakpointDown } from '../styles/media'

import { NotificationAlertSection, AnimatedNotificationCount } from './NotificationAlert'

const mapStateToProps = state => {
  const alerts = selectNewAlerts(state)

  return { alerts }
}

const holderOpenCss = css`
  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px 9px;
    border-color: transparent transparent ${props => props.theme.colors.gallery} transparent;
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 20;
    left: 4px;
  }
`

const DropdownWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
`

const DropdownButtonWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
  ${props => (props.isOpened ? holderOpenCss : '')};
`

const DropdownButton = styled.button`
  display: flex;
  width: 22px;
  border: none;
  padding: 0;
  background: none;
  font-size: 22px;
  position: relative;
  cursor: pointer;
  z-index: ${props => (props.isOpened ? 20 : 1)};
  color: ${props => (props.isOpened ? props.theme.colors.white : props.theme.colors.gray)};
  ${mediaBreakpointUp('sm')`
    color: ${props => props.theme.colors.gray};
  `};
`

const DropdownMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: ${props => rgba(props.theme.colors.bigStone, 0.9)};
  z-index: 1000;
  ${mediaBreakpointUp('sm')`
    position: absolute;
    background: none;
    left: auto;
    right: ${props => (props.noAlerts ? 0 : '15px')};
    width: ${props => (props.noAlerts ? '234px' : '480px')};
    transform: translate(${props => (props.noAlerts ? '-50%' : 0)}, 0);
    left: ${props => (props.noAlerts ? '11px' : 'auto')};
    height: auto;
  `};
`

const DropdownMenuCloseBtn = styled.button`
  position: absolute;
  top: 22px;
  right: 15px;
  color: ${props => props.theme.colors.white};
  border: none;
  font-size: 32px;
  background: none;
  font-weight: normal;
  padding: 0;
`

const NotificationsHolder_ = styled.div`
  position: absolute;
  z-index: 999;
  top: 67px;
  left: 0;
  width: 100%;
  box-shadow: 1px 1px 3px 1px ${props => rgba(props.theme.colors.bigStone, 0.1)};
  overflow: scroll;
  ${mediaBreakpointDown('xs')`
    padding-bottom: 67px;
    bottom: 0;
  `};
  ${mediaBreakpointUp('xs')`
    max-height: 400px;
  `};
`
const NotificationsHolder = enhanceWithClickOutside(NotificationsHolder_)

const NoAlertsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.gray};
  background: ${props => props.theme.colors.white};
  ${mediaBreakpointUp('sm')`
    font-size: 13px;
    padding: 20px 10px;
  `};
`

const NoAlertsIcon = styled(Icon)`
  margin-bottom: 24px;
  font-size: 60px;
  ${mediaBreakpointUp('sm')`
    margin-bottom: 10px;
    font-size: 20px;
  `};
`

const NoAlertsView = () => (
  <NoAlertsWrapper>
    <NoAlertsIcon icon="no-alerts-bell" />
    <span>You don’t have any alerts</span>
  </NoAlertsWrapper>
)

function NotificationDropdown({ alerts, dismissAlert, dismissAlertGroup }) {
  const [isOpened, setIsOpened] = React.useState(false)
  const router = useRouter()

  React.useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (innerWidth < 545) {
      if (isOpened) {
        document.body.classList.add('body-overflow-hidden')
      } else {
        document.body.classList.remove('body-overflow-hidden')
      }
    }
  }, [isOpened])

  React.useEffect(() => {
    setIsOpened(false)
  }, [router.asPath])

  const grouped = alerts.groupBy(a => a.grouping)
  const isEmpty = !alerts || alerts.size === 0

  const dropdownToggleHandler = e => {
    e.preventDefault()
    if (!isEmpty) {
      setIsOpened(opened => !opened)
    }
  }

  return (
    <DropdownWrapper>
      <DropdownButtonWrapper isOpened={isOpened}>
        <DropdownButton onClick={dropdownToggleHandler} isOpened={isOpened}>
          <SvgIcon icon={isOpened ? iconBellFilled : iconBell} size={22} />
          <AnimatedNotificationCount count={alerts.size} isOpened={isOpened} />
        </DropdownButton>
      </DropdownButtonWrapper>
      {isOpened && (
        <DropdownMenu
          handleClickOutside={() => {
            setIsOpened(false)
          }}
          noAlerts={isEmpty}
        >
          <MediaQuery query="(max-width: 544px)">
            <DropdownMenuCloseBtn onClick={dropdownToggleHandler}>
              <Icon icon="cancel" />
            </DropdownMenuCloseBtn>
          </MediaQuery>
          <NotificationsHolder
            handleClickOutside={() => {
              setIsOpened(false)
            }}
          >
            {isEmpty ? (
              <NoAlertsView />
            ) : (
              grouped
                .map((alertsInner, section) => (
                  <NotificationAlertSection
                    dismissAlert={dismissAlert}
                    dismissAlertGroup={dismissAlertGroup}
                    alerts={alertsInner}
                    group={section}
                    key={section}
                  />
                ))
                .valueSeq()
                .toArray()
            )}
          </NotificationsHolder>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  )
}

const { dismissAlert, dismissAlertGroup } = notificationAlert.actions

export default connect(mapStateToProps, { dismissAlert, dismissAlertGroup })(NotificationDropdown)
