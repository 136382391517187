import PropTypes from 'prop-types'
import * as React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { fontWeight } from 'uikit/styles/theme'

const pulse = keyframes`
  0% {transform: scale(1);}
  20% {transform: scale(1.4);} 
  50% {transform: scale(.9);} 
  80% {transform: scale(1.2);} 
  100% {transform: scale(1);}
`

const animation = () =>
  css`
    ${pulse} 1.5s 1;
  `

const StyledNotificationCount = styled.span`
  position: absolute;
  top: -5px;
  right: -8px;
  width: 19px;
  height: 19px;
  display: block;
  z-index: 1;
  font-weight: ${fontWeight.medium};
  color: ${props => props.theme.colors.white};
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  padding: 2px;
  border-radius: 50%;
  animation: ${props => (props.animate ? animation : 'none')};
  background: ${props =>
    props.isOpened ? props.theme.colors.stTropaz : props.theme.colors.tussock};
`

function NotificationCount({ count, animate, isOpened }) {
  if (count && count > 0) {
    return (
      <StyledNotificationCount animate={animate} isOpened={isOpened}>
        {count}
      </StyledNotificationCount>
    )
  }
  return null
}
NotificationCount.propTypes = {
  count: PropTypes.number.isRequired,
  animate: PropTypes.bool.isRequired,
}

export default NotificationCount
