import React from 'react'

import { View } from 'uikit'

const SecondaryLayout = props => (
  <View bg="white" flexGrow={1} flexDirection="column" alignItems="stretch">
    {props.children}
  </View>
)
export default SecondaryLayout

export const asLayout = page => <SecondaryLayout>{page}</SecondaryLayout>
