import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MediaQuery from 'react-responsive'
import { compose } from 'ramda'

import { init } from '../actions/initActions'
import { logout } from '../actions/loginActions'

import { Header } from '../components/headers'
import { Footer } from '../components/footers'
import TopNav from '../components/TopNav'
import ReasonSystemMessages from '../reason/systemMessages/SystemMessageDisplay.bs'
import { useNewVersionNotice } from '../reason/newVersionNotice/UseNewVersionNotice.bs'
import * as ClientAuthorizationCheck from '../reason/common/ClientAuthorizationCheck/ClientAuthorizationCheck.bs'
import Loader from 'uikit/atoms/Loader/Loader'
import { asLayout as mainLayout } from 'components/MainLayout'
import { asLayout as secondaryLayout } from 'components/SecondaryLayout'

const mapStateToProps = state => ({
  session: state.session,
})

const mapDispatchToProps = dispatch => bindActionCreators({ init, logout }, dispatch)

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.wildSand};
  display: flex;
  flex-direction: column;
`

function MainApp(props) {
  const [topNavIsOpened, setTopNavIsOpened] = React.useState(false)
  useNewVersionNotice()
  ClientAuthorizationCheck.use()

  const mobileNavToggle = () => {
    setTopNavIsOpened(!topNavIsOpened)
  }

  const {
    session: { isLoggedIn, initializing, user },
  } = props

  const userRole = user && user.role
  const body = initializing ? <Loader flexGrow={1} /> : props.children

  const mobileNav = topNavIsOpened ? (
    <TopNav isLoggedIn={isLoggedIn} mobileNavToggle={mobileNavToggle} userRole={userRole} />
  ) : null

  return (
    <PageWrapper id="app">
      <Header
        isLoggedIn={isLoggedIn}
        handleSignout={props.logout}
        mobileNavToggle={mobileNavToggle}
        topNavIsOpened={topNavIsOpened}
        userRole={userRole}
      />
      <MediaQuery minWidth={545}>
        <TopNav isLoggedIn={isLoggedIn} userRole={userRole} />
      </MediaQuery>
      <MediaQuery maxWidth={544}>{mobileNav}</MediaQuery>
      <ReasonSystemMessages />
      {body}
      <Footer />
    </PageWrapper>
  )
}

MainApp.propTypes = {
  logout: PropTypes.func.isRequired,
  session: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
    user: PropTypes.object,
    initializing: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node,
}
const ConnectedMainApp = connect(mapStateToProps, mapDispatchToProps)(MainApp)

export default ConnectedMainApp

export const asLayout = page => <ConnectedMainApp>{page}</ConnectedMainApp>
export const withMainLayout = compose(asLayout, mainLayout({}))
export const withMainLayoutNoPadding = compose(asLayout, mainLayout({ noPadding: true }))
export const withMainLayoutFlex = compose(asLayout, mainLayout({ flexDirection: 'column' }))
export const withMainLayoutFlexNoPadding = compose(
  asLayout,
  mainLayout({ flexDirection: 'column', noPadding: true })
)
export const withSecondaryLayout = compose(asLayout, secondaryLayout)
