// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Hooks = require("../../utils/Hooks.bs.js");
var React = require("react");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var SystemMessage = require("../../uikit/reason/molecules/SystemMessage/SystemMessage.bs.js");
var SystemMessages = require("./SystemMessages.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ReactTransitionGroup = require("react-transition-group");

function SystemMessageDisplay$SystemMessage(props) {
  var dismiss = props.dismiss;
  var message = props.message;
  return JsxRuntime.jsx(SystemMessage.make, {
              id: message.id,
              time: message.time,
              text: message.text,
              type_: message.type_,
              dismissible: Core__Option.map(dismiss, (function (param) {
                      return true;
                    })),
              handleOnDismiss: dismiss,
              children: Caml_option.some(props.children)
            });
}

var SystemMessage$1 = {
  make: SystemMessageDisplay$SystemMessage
};

var enter = Curry._1(Css.style, {
      hd: Css.opacity(0.1),
      tl: /* [] */0
    });

var enterActive = Curry._1(Css.style, {
      hd: Css.opacity(1.0),
      tl: {
        hd: Css.transition(700, undefined, "easeIn", "all"),
        tl: /* [] */0
      }
    });

var exit = Curry._1(Css.style, {
      hd: Css.opacity(1.0),
      tl: /* [] */0
    });

var exitActive = Curry._1(Css.style, {
      hd: Css.opacity(0.1),
      tl: {
        hd: Css.transition(700, undefined, "easeIn", "all"),
        tl: /* [] */0
      }
    });

var stickyContainer = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.zIndex(200),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  enter: enter,
  enterActive: enterActive,
  exit: exit,
  exitActive: exitActive,
  stickyContainer: stickyContainer
};

function SystemMessageDisplay$Spread(props) {
  return React.cloneElement(props.children, props.props);
}

var Spread = {
  make: SystemMessageDisplay$Spread
};

function SystemMessageDisplay(props) {
  var match = SystemMessages.useSystemMessages();
  var setDimensions = match.setDimensions;
  var removeMessage = match.removeMessage;
  var match$1 = Hooks.useComponentSize(false, undefined);
  var dimensions = match$1[1];
  React.useEffect((function (param) {
          Curry._1(setDimensions, dimensions);
          return (function (param) {
                    Curry._1(setDimensions, undefined);
                  });
        }), [dimensions]);
  return JsxRuntime.jsx(SystemMessageDisplay$Spread, {
              props: {
                "aria-live": "polite"
              },
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(ReactTransitionGroup.TransitionGroup, {
                          children: Core__List.toArray(Core__List.map(match.messages, (function (msg) {
                                      var render = msg.renderButtons;
                                      return JsxRuntime.jsx(ReactTransitionGroup.CSSTransition, {
                                                  classNames: {
                                                    enter: enter,
                                                    enterActive: enterActive,
                                                    exit: exit,
                                                    exitActive: exitActive
                                                  },
                                                  timeout: {
                                                    enter: 500,
                                                    exit: 500
                                                  },
                                                  children: JsxRuntime.jsx(SystemMessageDisplay$SystemMessage, {
                                                        message: msg,
                                                        dismiss: msg.dismissable ? removeMessage : undefined,
                                                        children: render !== undefined ? Curry._1(render, msg.id) : null
                                                      }, msg.id)
                                                });
                                    })))
                        }),
                    ref: Caml_option.some(match$1[0]),
                    className: stickyContainer
                  })
            });
}

var make = SystemMessageDisplay;

var $$default = SystemMessageDisplay;

exports.SystemMessage = SystemMessage$1;
exports.Styles = Styles;
exports.Spread = Spread;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* enter Not a pure module */
