import styled from 'styled-components'
import {
  boxShadow,
  space,
  fontSize,
  width,
  color,
  fontWeight,
  borderWidth,
  borderColor,
  textAlign,
} from 'styled-system'
import { lineHeight, borderStyle, textTransform, flexGrow, flexShrink } from './css'

const Div = styled.div`
  ${space} ${lineHeight} ${width} ${color} ${textTransform} ${fontWeight} ${fontSize} ${boxShadow} ${borderStyle} ${borderWidth} ${borderColor} ${flexGrow} ${flexShrink} ${textAlign};
`

export default Div
