import styled from 'styled-components'
import { flexWrap, alignItems, justifyContent, flex, flexDirection, alignSelf } from 'styled-system'

import Div from './Div'
import { flexGrow, flexShrink } from './css'

const Flex = styled(Div)`
  display: flex;
  ${flex} ${flexWrap} ${alignItems} ${flex} ${flexDirection} ${justifyContent} ${flexGrow} ${flexShrink} ${alignSelf};
`

export default Flex
