// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Css_Js_Core = require("bs-css/src/Css_Js_Core.bs.js");
var Next__Atoms = require("../next/Next__Atoms.bs.js");
var MenuButton = require("./MenuButton");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../common/Theme/Theme__Color.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MenuButton$1 = require("@reach/menu-button");

var Menu = {};

var make = MenuButton.LinkButton;

var LinkButton = {
  make: make
};

var make$1 = MenuButton.Button;

var Button = {
  make: make$1
};

var make$2 = MenuButton.SecondaryButton;

var SecondaryButton = {
  make: make$2
};

var make$3 = MenuButton.DarkSecondaryButton;

var DarkSecondaryButton = {
  make: make$3
};

var make$4 = MenuButton.PrimaryButton;

var PrimaryButton = {
  make: make$4
};

var $$External = {};

var listStyle = CssJs.style([
      CssJs.borderColor(Theme__Color.gallery),
      CssJs.color(Theme__Color.tuatara),
      CssJs.boxShadow(Css_Js_Core.Shadow.box(CssJs.px(0), CssJs.px(2), CssJs.px(5), undefined, undefined, CssJs.rgba(46, 46, 45, {
                    NAME: "num",
                    VAL: 0.07
                  }))),
      CssJs.minWidth({
            NAME: "rem",
            VAL: 12
          }),
      CssJs.fontSize({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.selector("a", [CssJs.textDecoration("none")]),
      CssJs.selector("[data-reach-menu-item][data-selected]", [CssJs.backgroundColor(Theme__Color.stTropaz)]),
      CssJs.selector("[data-reach-menu-item]", [
            CssJs.paddingTop({
                  NAME: "px",
                  VAL: 10
                }),
            CssJs.paddingBottom({
                  NAME: "px",
                  VAL: 10
                })
          ])
    ]);

function MenuButton$List(props) {
  return JsxRuntime.jsx(MenuButton$1.MenuList, {
              className: Core__Option.mapOr(props.className, listStyle, (function (c) {
                      return c + (" " + listStyle);
                    })),
              portal: props.portal,
              children: props.children
            });
}

var List = {
  $$External: $$External,
  listStyle: listStyle,
  make: MenuButton$List
};

var Item = {};

var ExternalLink = {};

var JS = {};

function MenuButton$Link(props) {
  return JsxRuntime.jsx(MenuButton$1.MenuLink, {
              className: props.className,
              children: props.children,
              href: props.to_,
              disabled: props.disabled,
              as: Next__Atoms.Link.make,
              onSelect: props.onSelect
            });
}

var Link = {
  JS: JS,
  make: MenuButton$Link
};

exports.Menu = Menu;
exports.LinkButton = LinkButton;
exports.Button = Button;
exports.SecondaryButton = SecondaryButton;
exports.DarkSecondaryButton = DarkSecondaryButton;
exports.PrimaryButton = PrimaryButton;
exports.List = List;
exports.Item = Item;
exports.ExternalLink = ExternalLink;
exports.Link = Link;
/* make Not a pure module */
