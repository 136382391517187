import PropTypes from 'prop-types'
import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'

import { mediaBreakpointUp } from 'styles/media'

import { Container } from './MarkupHelpers'
import { useSavedSearchesEnabled } from '../reason/featureFlipper/FeaturesV3.bs'
import { useCurrentUserIsClientWithAutonotifyBuyerNeed } from '../reason/common/CurrentUser/CurrentUser.bs'

const StyledTopNavSection = styled.section`
  background: ${({ theme: { name, colors } }) =>
    name === 'light' ? colors.bigStone : colors.bigStoneAlt};
`

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: ${props => props.theme.colors.alto};
  background: ${props => props.theme.colors.bigStoneAlt};
  span {
    display: inline-block;
    padding: 13px 15px;
    border-width: 0 0 0 4px;
    border-style: solid;
    border-color: transparent;
  }
  &:hover {
    color: ${({ theme: { name, colors } }) => (name === 'light' ? colors.white : colors.oldLace)};
    text-decoration: none;
  }
  &:focus {
    color: ${props => props.theme.colors.aquaHaze};
  }
  &.current {
    color: ${({ theme: { name, colors } }) => (name === 'light' ? colors.white : colors.oldLace)};
    font-weight: ${props => props.theme.fontWeight.bold};
    span {
      border-style: solid;
      border-color: ${props => props.theme.colors.tussock};
      border-width: 0 0 0 4px;
    }
  }
  ${mediaBreakpointUp('sm')`
    font-size: 14px;
    line-height: 30px;
    padding: 0 15px;
    display: inline-block;
    background: ${({ theme: { name, colors } }) =>
      name === 'light' ? colors.bigStone : colors.bigStoneAlt};
    span {
      padding: 9px 0 8px 0;
      border-bottom: 3px solid transparent;
    }
    &.current {
      span {
        border-width: 0 0 3px 0;
      }
    }
  `};
  ${mediaBreakpointUp('sm')`
    font-size: 16px;
  `};
`

const StyledTopNavList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0 -1rem;
  li:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.bigStoneDark};
  }
  ${mediaBreakpointUp('sm')`
    margin: 0;
    flex-direction: row;
    li {
      border-bottom: none;
    }
  `};
  li:first-of-type {
    a {
      padding-left: 0;
    }
  }
`

const NavLink = ({ href, text, mobileNavToggle }) => {
  const router = useRouter()

  // Conversations is a special case here as we want that link to look active
  // even when the user is on /conversations/some-conversation-uuid or
  // conversations/new
  const isActive =
    (router.pathname.split('/')[1] === 'conversations' &&
      router.pathname.split('/')[1] === href.split('/')[1]) ||
    router.pathname === href

  return (
    <StyledLink href={href} className={isActive ? 'current' : ''} onClick={mobileNavToggle}>
      <span>{text}</span>
    </StyledLink>
  )
}

const loggedInContent = ({ userRole, mobileNavToggle, showSavedSearchNavItem }) => {
  const agentLinks = [
    { href: '/', text: 'Home' },
    { href: '/me', text: 'My Dashboard' },
    { href: '/conversations', text: 'Conversations' },
    { href: '/chapter', text: 'Chapter Activity' },
  ]

  const clientLinks = [{ href: '/client', text: 'Home' }].concat(
    showSavedSearchNavItem ? [{ href: '/client/saved-searches', text: 'Saved Searches' }] : []
  )

  const links = userRole === 'agent' ? agentLinks : clientLinks

  return (
    <StyledTopNavList>
      {links.map((props, idx) => (
        <li key={idx}>
          <NavLink mobileNavToggle={mobileNavToggle} {...props} />
        </li>
      ))}
    </StyledTopNavList>
  )
}

const TopNav = ({ isLoggedIn, userRole, mobileNavToggle }) => {
  const savedSearchesEnabled = useSavedSearchesEnabled()
  const userIsClientWithAutonotifyBuyerNeed = useCurrentUserIsClientWithAutonotifyBuyerNeed()
  return isLoggedIn ? (
    <StyledTopNavSection>
      <Container>
        {loggedInContent({
          userRole,
          mobileNavToggle,
          showSavedSearchNavItem: savedSearchesEnabled || userIsClientWithAutonotifyBuyerNeed,
        })}
      </Container>
    </StyledTopNavSection>
  ) : null
}

TopNav.proptypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.oneOf(['agent', 'client']),
  mobileNavToggle: PropTypes.func.isRequired,
}

export default TopNav
