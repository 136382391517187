import PropTypes from 'prop-types'
import { default as React, Component } from 'react'

import NotificationCount from './NotificationCount'

class AnimatedNotificationCount extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
  }

  state = {
    animate: false,
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.count > this.props.count) {
      this.setState({ animate: true }, () => {
        const self = this
        setTimeout(() => self.setState({ animate: false }), 2000)
      })
    }
  }

  render() {
    const { animate } = this.state
    return <NotificationCount {...this.props} animate={animate} />
  }
}

export default AnimatedNotificationCount
