import ClientNeedMatchAlert from './ClientNeedMatchAlert'
import PropertyMatchAlert from './PropertyMatchAlert'
import MessageAlert from './MessageAlert'

const components = {
  PrivateBuyerNeedMatch: ClientNeedMatchAlert,
  BuyerNeedMatch: ClientNeedMatchAlert,
  RentalNeedMatch: ClientNeedMatchAlert,
  PrivatePropertyMatch: PropertyMatchAlert,
  PropertyMatch: PropertyMatchAlert,
  RentalPropertyMatch: PropertyMatchAlert,
  ConversationMessageNotification: MessageAlert,
}

const alertComponent = alertType => components[alertType]

export { alertComponent }
