import { style } from 'styled-system'

const lineHeight = style({
  prop: 'lh',
  cssProperty: 'lineHeight',
})

const borderStyle = style({
  prop: 'bs',
  cssProperty: 'borderStyle',
})

const whiteSpace = style({
  prop: 'ws',
  cssProperty: 'whiteSpace',
})

const textTransform = style({
  prop: 'tt',
  cssProperty: 'textTransform',
})

const flexGrow = style({
  prop: 'flg',
  cssProperty: 'flexGrow',
})

const flexShrink = style({
  prop: 'fls',
  cssProperty: 'flexShrink',
})

export { lineHeight, borderStyle, whiteSpace, textTransform, flexGrow, flexShrink }
