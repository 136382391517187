import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'reason/next/Link'
import formatDistance from 'date-fns/formatDistance'
import { SecondaryButton } from 'uikit'
import Image from 'next/image'

import avatarSrcUrl from 'utils/avatarSrcUrl'
import { propertyDetailsGateJs as PropertyDetailsGate } from 'reason/subscription/FeatureGate.bs'

import { Alert } from '../../../reducers/alerts'
import DismissAlert from './DismissAlert'

const SinglePropertyMatchAlert = ({ client }) => (
  <Link href={`/my_clients/${client.id}`}>{client.displayShortName()}</Link>
)

const MultiplePropertyMatchAlert = ({ clients, agentMatchLog, alert }) => (
  <Link href={`/match_log/${agentMatchLog.id}`}>
    {clients.size} of your {alert.alertType === 'RentalPropertyMatch' ? 'clients' : 'buyers'}
  </Link>
)

const MatchingClients = ({ clients, agentMatchLog, alert }) => {
  if (clients.size > 1) {
    return (
      <MultiplePropertyMatchAlert clients={clients} alert={alert} agentMatchLog={agentMatchLog} />
    )
  }

  return <SinglePropertyMatchAlert client={clients.get(0)} />
}

MatchingClients.propTypes = {
  clients: PropTypes.object.isRequired,
  agentMatchLog: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
}

const MatchLink = ({ agentMatchLog, onDismiss }) => (
  <Link href={`/match_log/${agentMatchLog.id}`}>
    <SecondaryButton onClick={onDismiss} size="small">
      View Match
    </SecondaryButton>
  </Link>
)

MatchLink.propTypes = {
  agentMatchLog: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

const PrivatePropertyMatchAlert = ({ alert, onDismiss }) => (
  <div className="item-inner">
    {!alert.agent ? (
      <>
        <div className="userpic-holder hidden-xs-down">
          <Image
            unoptimized
            width={50}
            height={50}
            layout="fixed"
            src={avatarSrcUrl()}
            alt="Anonymous agent"
          />
        </div>
      </>
    ) : (
      <>
        <div className="userpic-holder hidden-xs-down">
          <PropertyDetailsGate otherAgentId={alert.agent.id}>
            <Link href={`/agents/${alert.agent.id}`}>
              <Image
                unoptimized
                layout="fixed"
                width={50}
                height={50}
                src={avatarSrcUrl(alert.agent.avatar ? alert.agent.avatar.toJS() : null)}
                alt=""
              />
            </Link>
          </PropertyDetailsGate>
          <PropertyDetailsGate otherAgentId={alert.agent.id} negate>
            <Image unoptimized layout="fixed" width={50} height={50} src={avatarSrcUrl()} alt="" />
          </PropertyDetailsGate>
        </div>
      </>
    )}
    <div className="content-holder">
      <div>
        {alert.agent ? (
          <>
            <PropertyDetailsGate otherAgentId={alert.agent.id}>
              <Link href={`/agents/${alert.agent.id}`} className="user-name">
                {alert.agent.displayName()}
              </Link>
            </PropertyDetailsGate>
            <PropertyDetailsGate otherAgentId={alert.agent.id} negate>
              A TAN agent
            </PropertyDetailsGate>
          </>
        ) : (
          'A TAN agent'
        )}{' '}
        {alert.alertCause === 'updated' ? 'updated' : 'added'} a{' '}
        {alert.alertType === 'RentalPropertyMatch' ? 'rental property' : 'property'} that matches{' '}
        {alert.clients.size === 1 &&
          `your ${alert.alertType === 'RentalPropertyMatch' ? 'client' : 'buyer'} `}
        <MatchingClients
          alert={alert}
          clients={alert.clients}
          agentMatchLog={alert.agentMatchLog}
        />
      </div>
      <div className="time">
        {formatDistance(new Date(alert.agentMatchLog.matchedAt), new Date())} ago
      </div>
    </div>
    <div className="action-holder">
      <MatchLink agentMatchLog={alert.agentMatchLog} onDismiss={onDismiss} />
    </div>
    <div className="notification-close-holder">
      <DismissAlert onDismiss={onDismiss} />
    </div>
  </div>
)

PrivatePropertyMatchAlert.propTypes = {
  alert: PropTypes.instanceOf(Alert),
}

export default PrivatePropertyMatchAlert
