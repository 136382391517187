import { MenuButton } from '@reach/menu-button'
import { makeButton } from 'uikit/atoms/Buttons/Button'
import { makeLinkButton } from 'uikit/atoms/Buttons/LinkButton'
import { makePrimaryButton } from 'uikit/atoms/Buttons/PrimaryButton'
import { makeSecondaryButton } from 'uikit/atoms/Buttons/SecondaryButton'
import { makeDarkSecondaryButton } from 'uikit/atoms/Buttons/DarkSecondaryButton'

export const Button = makeButton(MenuButton)
export const LinkButton = makeLinkButton(MenuButton)
export const SecondaryButton = makeSecondaryButton(Button)
export const PrimaryButton = makePrimaryButton(Button)
export const DarkSecondaryButton = makeDarkSecondaryButton(Button)
