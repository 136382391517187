// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");
var CurrentUser = require("../common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function featureIsEnabled(feature, context) {
  return Core__Option.isSome(Core__List.getBy(context.plan.features, (function (enabledFeature) {
                    return enabledFeature === feature;
                  })));
}

function isCurrentUser(otherAgentId, currentUserContext) {
  var match = currentUserContext.user;
  if (otherAgentId !== undefined && match !== undefined) {
    return otherAgentId === match.id;
  } else {
    return false;
  }
}

function shouldShowPropertyDetails(otherAgentId, context) {
  if (isCurrentUser(otherAgentId, context)) {
    return true;
  } else {
    return featureIsEnabled("PROPERTY_DETAILS", context);
  }
}

function FeatureGate$PropertyDetailsGate(props) {
  var children = props.children;
  var __negate = props.negate;
  var negate = __negate !== undefined ? __negate : false;
  var userContext = CurrentUser.useCurrentUser();
  var shouldShow = shouldShowPropertyDetails(props.otherAgentId, userContext);
  if (shouldShow) {
    if (negate) {
      return null;
    } else {
      return children;
    }
  } else if (negate) {
    return children;
  } else {
    return null;
  }
}

var PropertyDetailsGate = {
  make: FeatureGate$PropertyDetailsGate
};

var propertyDetailsGateJs = FeatureGate$PropertyDetailsGate;

exports.featureIsEnabled = featureIsEnabled;
exports.isCurrentUser = isCurrentUser;
exports.shouldShowPropertyDetails = shouldShowPropertyDetails;
exports.PropertyDetailsGate = PropertyDetailsGate;
exports.propertyDetailsGateJs = propertyDetailsGateJs;
/* CurrentUser Not a pure module */
