import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'reason/next/Link'
import { SecondaryButton } from 'uikit'
import { formatForPriceRange } from 'utils/Numeral.bs'
import Image from 'next/image'

import avatarSrcUrl from 'utils/avatarSrcUrl'

import { Alert } from '../../../reducers/alerts'
import DismissAlert from './DismissAlert'

const PropertySummary = ({ context }) => {
  const property = context.get('property').toJS()

  let displayString

  if (!property.address) {
    /* The owning agent will always at have the address,
       hence why we can get await with 'their property' without any further checks */
    if (!property.neighborhood && !property.city) {
      displayString = 'their property'
    } else {
      displayString = `their property in ${[property.neighborhood, property.city]
        .filter(p => !!p)
        .join(', ')}`
    }
  } else {
    displayString = [property.address, property.neighborhood, property.city]
      .filter(p => !!p)
      .join(', ')
  }

  if (property.client) {
    return <Link href={`/my_clients/${property.client.id}`}> {displayString} </Link>
  } else if (property.visibility === 'public') {
    return <Link href={`/properties/${property.id}`}> {displayString} </Link>
  }

  return <span> {displayString} </span>
}

const RentalPropertySummary = ({ context }) => {
  const property = context.get('rentalProperty').toJS()

  let displayString

  if (!property.address) {
    /* The owning agent will always at have the address,
       hence why we can get await with 'their property' without any further checks */
    if (!property.city) {
      displayString = 'their rental property'
    } else {
      displayString = `their rental property in ${property.city}`
    }
  } else {
    displayString = [property.address, property.city].filter(p => !!p).join(', ')
  }

  if (property.client) {
    return <Link href={`/my_clients/${property.client.id}`}> {displayString} </Link>
  }
  return <Link href={`/properties/${property.id}`}> {displayString} </Link>
}

RentalPropertySummary.propTypes = {
  context: PropTypes.object.isRequired,
}

const RentalNeedSummary = ({ context }) => {
  const rentalNeed = context.get('rentalNeed').toJS()

  let displayString

  if (rentalNeed.client) {
    displayString = rentalNeed.client.contacts
      .map(contact => [contact.firstName, contact.lastName].filter(p => !!p).join(' '))
      .join(' and ')
      .concat("'s rental need")
  } else {
    displayString = `their rental need (${formatForPriceRange(
      rentalNeed.minPrice
    )} - ${formatForPriceRange(rentalNeed.maxPrice)}/month)`
  }

  if (rentalNeed.client) {
    return <Link href={`/my_clients/${rentalNeed.client.id}`}> {displayString} </Link>
  }
  return <Link href={`/client_needs/${rentalNeed.id}`}> {displayString} </Link>
}

RentalNeedSummary.propTypes = {
  context: PropTypes.object.isRequired,
}

const BuyerNeedSummary = ({ context }) => {
  const buyerNeed = context.get('buyerNeed').toJS()

  let displayString

  if (buyerNeed.client) {
    displayString = buyerNeed.client.contacts
      .map(contact => [contact.firstName, contact.lastName].filter(p => !!p).join(' '))
      .join(' and ')
      .concat("'s buyer need")
  } else {
    displayString = (
      buyerNeed.visibility === 'public' ? 'their buyer need' : 'their quiet buyer'
    ).concat(
      buyerNeed.minPrice && buyerNeed.maxPrice
        ? ` (${formatForPriceRange(buyerNeed.minPrice)} - ${formatForPriceRange(
            buyerNeed.maxPrice
          )})`
        : ''
    )
  }

  if (buyerNeed.client) {
    return <Link href={`/my_clients/${buyerNeed.client.id}`}> {displayString} </Link>
  } else if (buyerNeed.visibility === 'public') {
    return <Link href={`/client_needs/${buyerNeed.id}`}> {displayString} </Link>
  }

  return <span> {displayString} </span>
}

BuyerNeedSummary.propTypes = {
  context: PropTypes.object.isRequired,
}

const MessageSummary = ({ context }) => (
  <Link href={`/messages/${context.getIn(['message', 'id'])}`}>
    {context.getIn(['message', 'subject'])}
  </Link>
)

MessageSummary.propTypes = {
  context: PropTypes.object.isRequired,
}

const NewMessageSummary = ({ context, message }) => {
  const msgText =
    message.get('message_type') === 'initial'
      ? ' sent you a direct message about '
      : ' replied to your direct message about '
  switch (context.get('context_type')) {
    case 'property':
      return (
        <>
          {msgText}
          <PropertySummary context={context} />
        </>
      )
    case 'rental_property':
      return (
        <>
          {msgText}
          <RentalPropertySummary context={context} />
        </>
      )
    case 'buyer_need':
      return (
        <>
          {msgText}
          <BuyerNeedSummary context={context} />
        </>
      )
    case 'rental_need':
      return (
        <>
          {msgText}
          <RentalNeedSummary context={context} />
        </>
      )
    case 'message':
      return (
        <>
          {' replied to '}
          <MessageSummary context={context} />
        </>
      )
    default:
      return null
  }
}

const MessageAlert = ({ alert, onDismiss }) => {
  const context = alert.conversation.get('context')
  const message = alert.conversation.get('message')

  return (
    <div className="item-inner">
      <div className="userpic-holder hidden-xs-down">
        {alert.agent ? (
          <Link href={`/agents/${alert.agent.id}`}>
            <Image
              unoptimized
              width={50}
              height={50}
              layout="fixed"
              src={avatarSrcUrl(alert.agent.avatar ? alert.agent.avatar.toJS() : null)}
              alt={alert.agent.displayName()}
            />
          </Link>
        ) : (
          <Image
            unoptimized
            width={50}
            height={50}
            layout="fixed"
            src={avatarSrcUrl()}
            alt="Anonymous agent"
          />
        )}
      </div>
      <div className="content-holder">
        {alert.agent ? (
          <Link href={`/agents/${alert.agent.id}`} className="user-name">
            {alert.agent.displayName()}
          </Link>
        ) : (
          'A TAN agent'
        )}
        <NewMessageSummary context={context} message={message} />
      </div>
      <div className="action-holder">
        <Link href={`/conversations/${alert.conversation.get('id')}`}>
          <SecondaryButton size="small" onClick={onDismiss}>
            View message
          </SecondaryButton>
        </Link>
      </div>
      <div className="notification-close-holder">
        <DismissAlert onDismiss={onDismiss} />
      </div>
    </div>
  )
}

MessageAlert.propTypes = {
  alert: PropTypes.instanceOf(Alert).isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export default MessageAlert
