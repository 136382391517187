// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Next = require("../../next/Next.bs.js");
var React = require("react");
var CurrentUser = require("../CurrentUser/CurrentUser.bs.js");

var allowedRoutes = [
  "/client",
  "/client/account"
];

function use(param) {
  var session = CurrentUser.useCurrentSession();
  var router = Next.useRouter();
  var pathname = Next.Router.pathname(router);
  React.useEffect((function (param) {
          if (typeof session === "object" && session.TAG === "Client") {
            var match = session._0.status;
            if ((match === "ACCESS_REVOKED" || match === "AGENT_NOT_AUTHORIZED") && !allowedRoutes.some(function (p) {
                    return p === pathname;
                  })) {
              Next.Router.replace("/client", undefined, undefined, router);
            }
            
          }
          
        }), [
        session,
        pathname
      ]);
}

exports.allowedRoutes = allowedRoutes;
exports.use = use;
/* Next Not a pure module */
