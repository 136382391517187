// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var SystemMessages = require("../systemMessages/SystemMessages.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var UseCodeHasChangedJs = require("./useCodeHasChanged.js");

function useCodeHasChanged(prim) {
  return UseCodeHasChangedJs.useCodeHasChanged();
}

function useNewVersionNotice(param) {
  var codeHasChanged = UseCodeHasChangedJs.useCodeHasChanged();
  var match = SystemMessages.useSystemMessages();
  var addMessage = match.addMessage;
  React.useEffect((function (param) {
          if (codeHasChanged === true) {
            Curry._1(addMessage, SystemMessages.Message.make("updated-code-notice", "info", "A new version of TAN is available. Please refresh this page to upgrade", 0, (function (param) {
                        return JsxRuntime.jsx(Buttons__Jsx3.OutlineButton.make, {
                                    onClick: (function (param) {
                                        window.location.reload();
                                      }),
                                    children: "Refresh"
                                  });
                      }), false, undefined));
          }
          
        }), [codeHasChanged]);
}

exports.useCodeHasChanged = useCodeHasChanged;
exports.useNewVersionNotice = useNewVersionNotice;
/* react Not a pure module */
