import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'uikit'

const DismissAlert = ({ onDismiss }) => {
  const onClick = e => {
    e.preventDefault()

    onDismiss()
  }

  return (
    <a href="#" className="notification-close-trigger" onClick={onClick}>
      <Icon icon="cancel" />
    </a>
  )
}

DismissAlert.propTypes = {
  onDismiss: PropTypes.func.isRequired,
}

export default DismissAlert
