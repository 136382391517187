// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var EventLogs = require("../EventLogs.bs.js");
var ViewStack = require("../common/ViewStack.bs.js");
var FeaturesV3 = require("../featureFlipper/FeaturesV3.bs.js");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var MediaQuery = require("../common/Media/MediaQuery.bs.js");
var MenuButton = require("../bindings/MenuButton.bs.js");
var Text__Jsx3 = require("../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CurrentUser = require("../common/CurrentUser/CurrentUser.bs.js");
var ReactRender = require("../utils/ReactRender.bs.js");
var Types__User = require("../user/Types__User.bs.js");
var Avatar__Jsx3 = require("../../uikit/reason/atoms/Avatar/Avatar__Jsx3.bs.js");
var FocusVisible = require("../../uikit/reason/helpers/FocusVisible.bs.js");
var Theme__Color = require("../common/Theme/Theme__Color.bs.js");
var IsTouchDevice = require("is-touch-device");
var JsxRuntime = require("react/jsx-runtime");
var MenuButton$1 = require("@reach/menu-button");
var CurrentUser__Types = require("../common/CurrentUser/CurrentUser__Types.bs.js");
var Theme__Color__Vars = require("../common/Theme/Theme__Color__Vars.bs.js");
var ClientAccess__Components__Avatar = require("../ClientAccess/components/ClientAccess__Components__Avatar.bs.js");

((require('@reach/menu-button/styles.css')));

var buttonStyle = Curry._1(Css.style, {
      hd: Css.unsafe("color", "inherit"),
      tl: {
        hd: Css.unsafe("font", "inherit"),
        tl: {
          hd: Css.textAlign(Css.inherit_),
          tl: {
            hd: Css.unsafe("WebkitFontSmoothing", "inherit"),
            tl: {
              hd: Css.unsafe("WebkitAppearance", "none"),
              tl: {
                hd: Css.important(Css.borderStyle("none")),
                tl: {
                  hd: Css.marginLeft({
                        NAME: "px",
                        VAL: 30
                      }),
                  tl: {
                    hd: Css.important(Css.padding({
                              NAME: "px",
                              VAL: 0
                            })),
                    tl: {
                      hd: Css.lineHeight("normal"),
                      tl: {
                        hd: Css.background("transparent"),
                        tl: {
                          hd: Css.overflow("visible"),
                          tl: {
                            hd: Css.width("auto"),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.important(Css.textTransform("capitalize")),
                                    tl: {
                                      hd: Css.selector("&[aria-expanded='true']", {
                                            hd: Css.before({
                                                  hd: Css.contentRule({
                                                        NAME: "text",
                                                        VAL: "\" \""
                                                      }),
                                                  tl: {
                                                    hd: Css.display("block"),
                                                    tl: {
                                                      hd: Css.backgroundColor("transparent"),
                                                      tl: {
                                                        hd: Css.height({
                                                              NAME: "px",
                                                              VAL: 0
                                                            }),
                                                        tl: {
                                                          hd: Css.width({
                                                                NAME: "px",
                                                                VAL: 0
                                                              }),
                                                          tl: {
                                                            hd: Css.position("absolute"),
                                                            tl: {
                                                              hd: Css.bottom({
                                                                    NAME: "px",
                                                                    VAL: -6
                                                                  }),
                                                              tl: {
                                                                hd: Css.left({
                                                                      NAME: "px",
                                                                      VAL: 19
                                                                    }),
                                                                tl: {
                                                                  hd: Css.borderLeft({
                                                                        NAME: "px",
                                                                        VAL: 6
                                                                      }, "solid", "transparent"),
                                                                  tl: {
                                                                    hd: Css.borderRight({
                                                                          NAME: "px",
                                                                          VAL: 6
                                                                        }, "solid", "transparent"),
                                                                    tl: {
                                                                      hd: Css.borderBottom({
                                                                            NAME: "px",
                                                                            VAL: 6
                                                                          }, "solid", Theme__Color.gallery),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: {
                                              hd: Css.after({
                                                    hd: Css.contentRule({
                                                          NAME: "text",
                                                          VAL: "\" \""
                                                        }),
                                                    tl: {
                                                      hd: Css.display("block"),
                                                      tl: {
                                                        hd: Css.backgroundColor("transparent"),
                                                        tl: {
                                                          hd: Css.height({
                                                                NAME: "px",
                                                                VAL: 0
                                                              }),
                                                          tl: {
                                                            hd: Css.width({
                                                                  NAME: "px",
                                                                  VAL: 0
                                                                }),
                                                            tl: {
                                                              hd: Css.position("absolute"),
                                                              tl: {
                                                                hd: Css.bottom({
                                                                      NAME: "px",
                                                                      VAL: -7
                                                                    }),
                                                                tl: {
                                                                  hd: Css.zIndex(1),
                                                                  tl: {
                                                                    hd: Css.left({
                                                                          NAME: "px",
                                                                          VAL: 19
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.borderLeft({
                                                                            NAME: "px",
                                                                            VAL: 6
                                                                          }, "solid", "transparent"),
                                                                      tl: {
                                                                        hd: Css.borderRight({
                                                                              NAME: "px",
                                                                              VAL: 6
                                                                            }, "solid", "transparent"),
                                                                        tl: {
                                                                          hd: Css.borderBottom({
                                                                                NAME: "px",
                                                                                VAL: 6
                                                                              }, "solid", {
                                                                                NAME: "rgb",
                                                                                VAL: [
                                                                                  255,
                                                                                  255,
                                                                                  255
                                                                                ]
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: FocusVisible.focusStyle
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listStyle = Curry._1(Css.style, {
      hd: Css.marginTop({
            NAME: "px",
            VAL: 5
          }),
      tl: {
        hd: Css.zIndex(200),
        tl: {
          hd: Css.position("relative"),
          tl: /* [] */0
        }
      }
    });

function ProfileDropdown$ProfileMenu$BaseMenu(props) {
  var buttonContentMobile = props.buttonContentMobile;
  var buttonContent = props.buttonContent;
  var match = FocusVisible.useFocusVisible(undefined);
  var isDesktop = MediaQuery.isDesktop();
  return JsxRuntime.jsxs(MenuButton$1.Menu, {
              children: [
                JsxRuntime.jsx(MenuButton.Button.make, {
                      className: Curry._1(Css.merge, {
                            hd: buttonStyle,
                            tl: {
                              hd: match.className,
                              tl: /* [] */0
                            }
                          }),
                      onFocus: match.onFocus,
                      onBlur: match.onBlur,
                      children: JsxRuntime.jsx(ViewStack.make, {
                            alignItems: "center",
                            justifyContent: "flexStart",
                            flexDirection: "row",
                            children: Caml_option.some(buttonContentMobile !== undefined && !isDesktop ? Caml_option.valFromOption(buttonContentMobile) : buttonContent)
                          })
                    }),
                JsxRuntime.jsx(MenuButton.List.make, {
                      className: listStyle,
                      children: props.children
                    })
              ]
            });
}

var BaseMenu = {
  make: ProfileDropdown$ProfileMenu$BaseMenu
};

function ProfileDropdown$ProfileMenu$DefaultMenu(props) {
  var handleSignout = props.handleSignout;
  return JsxRuntime.jsx(ProfileDropdown$ProfileMenu$BaseMenu, {
              buttonContent: JsxRuntime.jsxs(ViewStack.make, {
                    space: {
                      NAME: "px",
                      VAL: 6
                    },
                    alignItems: "center",
                    justifyContent: "flexStart",
                    flexDirection: "row",
                    children: [
                      JsxRuntime.jsx("div", {
                            children: "Menu"
                          }),
                      JsxRuntime.jsx(View__Jsx3.make, {
                            alignItems: "baseline",
                            children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "ArrowDown",
                                      size: 20,
                                      color: Theme__Color__Vars.doveGray
                                    }))
                          })
                    ]
                  }),
              children: JsxRuntime.jsx(MenuButton$1.MenuItem, {
                    children: "Sign Out",
                    onSelect: (function (param) {
                        Curry._1(handleSignout, undefined);
                      })
                  })
            });
}

var DefaultMenu = {
  make: ProfileDropdown$ProfileMenu$DefaultMenu
};

function ProfileDropdown$ProfileMenu$ClientMenu(props) {
  var handleSignout = props.handleSignout;
  var match = props.client;
  var clientProfilePicEnabled = FeaturesV3.useFeatureFlipper("CAP_ClientRegistration") === "on";
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(ProfileDropdown$ProfileMenu$BaseMenu, {
                        buttonContent: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                clientProfilePicEnabled ? JsxRuntime.jsx(ClientAccess__Components__Avatar.make, {
                                        avatar: match.avatar
                                      }) : null,
                                JsxRuntime.jsxs(ViewStack.make, {
                                      space: {
                                        NAME: "px",
                                        VAL: 6
                                      },
                                      alignItems: "center",
                                      justifyContent: "flexStart",
                                      flexDirection: "row",
                                      children: [
                                        JsxRuntime.jsx(Text__Jsx3.make, {
                                              fontWeight: "bold",
                                              children: match.firstName + " " + match.lastName
                                            }),
                                        JsxRuntime.jsx(View__Jsx3.make, {
                                              alignItems: "baseline",
                                              children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                                        icon: "ArrowDown",
                                                        size: 14,
                                                        color: Theme__Color__Vars.silver
                                                      }))
                                            })
                                      ]
                                    })
                              ]
                            }),
                        buttonContentMobile: Caml_option.some(JsxRuntime.jsx(ViewStack.make, {
                                  space: {
                                    NAME: "px",
                                    VAL: 6
                                  },
                                  alignItems: "center",
                                  justifyContent: "flexStart",
                                  flexDirection: "row",
                                  children: Caml_option.some(JsxRuntime.jsx(View__Jsx3.make, {
                                            alignItems: "baseline",
                                            children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                                      icon: "ArrowDown",
                                                      size: 20,
                                                      color: Theme__Color__Vars.doveGray
                                                    }))
                                          }))
                                })),
                        children: [
                          JsxRuntime.jsx(MenuButton.Link.make, {
                                children: "Account Settings",
                                to_: "/client/account"
                              }),
                          JsxRuntime.jsx(MenuButton$1.MenuItem, {
                                children: "Sign Out",
                                onSelect: (function (param) {
                                    Curry._1(handleSignout, undefined);
                                  })
                              })
                        ]
                      }))
            });
}

var ClientMenu = {
  make: ProfileDropdown$ProfileMenu$ClientMenu
};

function ProfileDropdown$ProfileMenu$AgentMenu(props) {
  var handleSignout = props.handleSignout;
  var match = props.agent;
  var plan = match.plan;
  var user = match.user;
  var marketingProfileSettingsEnabled = FeaturesV3.useFeatureFlipper("MarketingProfileSettings") === "on";
  var isTouch = IsTouchDevice();
  return JsxRuntime.jsxs(ProfileDropdown$ProfileMenu$BaseMenu, {
              buttonContent: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(Avatar__Jsx3.make, {
                            src: Types__User.avatarURL(undefined, undefined, user),
                            name: Types__User.name(user),
                            size: "small"
                          }),
                      JsxRuntime.jsxs(ViewStack.make, {
                            ml: {
                              NAME: "px",
                              VAL: 10
                            },
                            space: {
                              NAME: "px",
                              VAL: 6
                            },
                            alignItems: "center",
                            justifyContent: "flexStart",
                            flexDirection: "row",
                            children: [
                              JsxRuntime.jsx(Text__Jsx3.make, {
                                    fontWeight: "bold",
                                    children: Types__User.name(user)
                                  }),
                              JsxRuntime.jsx(View__Jsx3.make, {
                                    alignItems: "baseline",
                                    children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                              icon: "ArrowDown",
                                              size: 14,
                                              color: Theme__Color__Vars.silver
                                            }))
                                  })
                            ]
                          })
                    ]
                  }),
              buttonContentMobile: Caml_option.some(JsxRuntime.jsx(Avatar__Jsx3.make, {
                        src: Types__User.avatarURL(undefined, undefined, user),
                        name: Types__User.name(user),
                        size: "small"
                      })),
              children: [
                match.tanStaff ? JsxRuntime.jsx(MenuButton.Link.make, {
                        children: "Switch chapter",
                        to_: "/switch-chapter"
                      }) : null,
                match.permissions.some(function (p) {
                      return p === "MEMBERSHIP_REQUESTS";
                    }) ? JsxRuntime.jsx(MenuButton.Link.make, {
                        children: "Add Qualified Agent",
                        to_: "/admin/add-qa"
                      }) : null,
                CurrentUser__Types.Plan.isFree(plan) || CurrentUser__Types.Plan.isTrial(plan) ? JsxRuntime.jsx(MenuButton.Link.make, {
                        children: JsxRuntime.jsxs(View__Jsx3.make, {
                              alignItems: "center",
                              justifyContent: "flexStart",
                              flexDirection: "row",
                              children: [
                                JsxRuntime.jsx(View__Jsx3.make, {
                                      mr: {
                                        NAME: "px",
                                        VAL: 5
                                      },
                                      alignItems: "center",
                                      justifyContent: "center",
                                      children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                                icon: "Favorite",
                                                size: 20,
                                                color: Theme__Color__Vars.tussock
                                              }))
                                    }),
                                JsxRuntime.jsx("span", {
                                      children: CurrentUser__Types.Plan.isTrial(plan) ? "Subscribe to Pro" : "Upgrade to Pro"
                                    })
                              ]
                            }),
                        to_: "/subscribe"
                      }) : null,
                ReactRender.ifTrue(JsxRuntime.jsx(MenuButton.Link.make, {
                          children: JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: "Marketing Profile"
                                      }),
                                  JsxRuntime.jsx("div", {
                                        className: "intercom_marketing-profile-dropdown-link",
                                        onClick: (function (e) {
                                            if (isTouch) {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              return ;
                                            }
                                            
                                          })
                                      })
                                ],
                                className: "flex items-center gap-2 "
                              }),
                          to_: "/account/marketing_profile"
                        }), marketingProfileSettingsEnabled),
                JsxRuntime.jsx(MenuButton.Link.make, {
                      children: "Account Settings",
                      to_: "/account"
                    }),
                JsxRuntime.jsx(MenuButton$1.MenuLink, {
                      children: "Help Center",
                      as_: "a",
                      target: "_blank",
                      href: "https://intercom.help/top-agent-network"
                    }),
                JsxRuntime.jsx(MenuButton$1.MenuItem, {
                      children: "Sign Out",
                      onSelect: (function (param) {
                          Curry._1(handleSignout, undefined);
                        })
                    })
              ]
            });
}

var AgentMenu = {
  make: ProfileDropdown$ProfileMenu$AgentMenu
};

function ProfileDropdown$ProfileMenu(props) {
  var handleSignout = props.handleSignout;
  var currentUser = CurrentUser.useCurrentSession();
  var recordEvent = EventLogs.useRecordEventByCurrentUser();
  var handleSignout$p = function (param) {
    Curry._3(recordEvent, undefined, "Logout", undefined);
    Curry._1(handleSignout, undefined);
  };
  if (typeof currentUser !== "object") {
    if (currentUser === "Error") {
      return JsxRuntime.jsx(ProfileDropdown$ProfileMenu$DefaultMenu, {
                  handleSignout: handleSignout
                });
    } else {
      return null;
    }
  } else if (currentUser.TAG === "Agent") {
    return JsxRuntime.jsx(ProfileDropdown$ProfileMenu$AgentMenu, {
                agent: currentUser._0,
                handleSignout: handleSignout$p
              });
  } else {
    return JsxRuntime.jsx(ProfileDropdown$ProfileMenu$ClientMenu, {
                client: currentUser._0,
                handleSignout: handleSignout$p
              });
  }
}

var ProfileMenu = {
  buttonStyle: buttonStyle,
  listStyle: listStyle,
  BaseMenu: BaseMenu,
  DefaultMenu: DefaultMenu,
  ClientMenu: ClientMenu,
  AgentMenu: AgentMenu,
  make: ProfileDropdown$ProfileMenu
};

var $$default = ProfileDropdown$ProfileMenu;

exports.ProfileMenu = ProfileMenu;
exports.default = $$default;
exports.__esModule = true;
/*  Not a pure module */
