// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Theme = require("../../common/Theme/Theme.bs.js");
var AvatarSrcUrl = require("../../../utils/AvatarSrcUrl.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../common/Theme/Theme__Color__Vars.bs.js");

function sizeToDiameter(x) {
  if (x === "normal") {
    return {
            NAME: "px",
            VAL: 40
          };
  } else {
    return {
            NAME: "px",
            VAL: 30
          };
  }
}

function sizeToFontSize(x) {
  if (x === "normal") {
    return {
            NAME: "px",
            VAL: 22
          };
  } else {
    return {
            NAME: "px",
            VAL: 16
          };
  }
}

function wrapper(size) {
  return CssJs.style([
              CssJs.width(sizeToDiameter(size)),
              CssJs.height(sizeToDiameter(size)),
              CssJs.display("flex"),
              CssJs.flexShrink(0),
              CssJs.borderRadius({
                    NAME: "percent",
                    VAL: 50
                  }),
              CssJs.justifyContent("center"),
              CssJs.alignItems("center"),
              CssJs.overflow("hidden"),
              CssJs.backgroundColor(Theme__Color__Vars.wildSand)
            ]);
}

var avatarStyle = CssJs.style([
      CssJs.display("block"),
      CssJs.width({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.height({
            NAME: "percent",
            VAL: 100
          })
    ]);

function initialsStyle(size) {
  return CssJs.style([
              CssJs.color(Theme__Color__Vars.gray),
              CssJs.fontSize(sizeToFontSize(size)),
              CssJs.fontWeight(Theme.FontWeight.regular),
              CssJs.textTransform("uppercase")
            ]);
}

var Styles = {
  totalDiameter: 40,
  sizeToDiameter: sizeToDiameter,
  sizeToFontSize: sizeToFontSize,
  wrapper: wrapper,
  avatarStyle: avatarStyle,
  initialsStyle: initialsStyle
};

function ClientAccess__Components__Avatar(props) {
  var __size = props.size;
  var avatar = props.avatar;
  var size = __size !== undefined ? __size : "normal";
  var tmp;
  switch (avatar.TAG) {
    case "ProfilePicture" :
        var profilePictureSize = size === "normal" ? 50 : 40;
        tmp = JsxRuntime.jsx("img", {
              className: avatarStyle,
              src: AvatarSrcUrl.from_key(profilePictureSize, profilePictureSize, avatar._0.key)
            });
        break;
    case "URL" :
        tmp = JsxRuntime.jsx("img", {
              className: avatarStyle,
              src: avatar._0
            });
        break;
    case "Initials" :
        tmp = JsxRuntime.jsx("span", {
              children: avatar._0,
              className: initialsStyle(size)
            });
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: wrapper(size)
            });
}

var make = ClientAccess__Components__Avatar;

exports.Styles = Styles;
exports.make = make;
/* avatarStyle Not a pure module */
